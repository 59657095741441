// src/api/axiosInstance.js
import axios from 'axios';
import storeAuth from '../store/auth'; // Asegúrate de importar el módulo de Vuex con el estado

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    addDataToRequest(config, 'adminToken', storeAuth.state);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function addDataToRequest(config, dataKey, storeState) {
  const data = storeState[dataKey];

  if (data && data != 'undefined') {
    if (config.method === 'post' || config.method === 'POST') {
      config.data = {
        ...config.data,
        [dataKey]: data,
      };
    } else {
      config.params = {
        ...config.params,
        [dataKey]: data,
      };
    }
  }
}

export default instance;
