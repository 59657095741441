<template>
  <div class="events-content section-padding">
    <div class="container">
      <div class="columns is-multiline is-centered">
        <div class="column is-12">
          <div class="columns is-multiline">
            <div class="column is-12">
              <SearchEvents :events="events" :onSelect="handleSelect" />
            </div>
            <div class="column is-12">
              <ListEvents :events="list" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import SearchEvents from './autocomplete.vue';
import ListEvents from './list.vue';
export default {
  name: 'Events',
  components: {
    SearchEvents,
    ListEvents,
  },
  data() {
    return {
      list: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadEvents();
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadEvents();
  },
  computed: {
    ...mapState({
      events: (state) => state.events.list,
    }),
  },
  methods: {
    ...mapActions({
      fetchEvents: 'events/fetchEvents',
    }),
    async loadEvents() {
      await this.fetchEvents();
      this.list = this.events;
    },
    handleSelect(option) {
      if (option && option.id) {
        this.list = [option];
      } else {
        this.list = this.events;
      }
    },
  },
};
</script>