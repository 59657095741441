const state = {
  admin: null,
  token: null,
  adminToken: null,
  list: [],
  options: [],
  cupon: [],
};

export default state;
