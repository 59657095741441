<template>
  <section class="login hero is-gray is-fullheight">
    <h1 class="title has-text-centered pt-5 has-text-weight-light has-text-warning-light">Taquilla VOY AL TEATRO</h1>
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-multiline">
          <div class="column is-4 is-offset-4">
            <div class="box">
              <figure class="avatar">
                <img src="http://voyalteatro.com/v.1.0.0/media/contenido/vat.png" />
              </figure>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoginForm from './form.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>