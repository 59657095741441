<template>
  <section class="autocomplete-events">
    <b-field label="Buscar eventos" class="has-text-white">
      <b-autocomplete
        v-model="search"
        :data="list"
        placeholder="Buscar ..."
        field="nombre"
        :loading="isFetching"
        @typing="getAsyncData"
        @select="handleSelect"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-left">
              <img width="32" :src="`${props.option.portada}`" />
            </div>
            <div class="media-content">
              <b>{{ props.option.nombre }}</b>
              <br />
              <small> Teatro: {{ props.option.teatro }} </small>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
  </section>
</template>

<script>
export default {
  props: {
    events: {
      type: Array,
      required: true,
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      search: '',
      isFetching: false,
    };
  },
  mounted() {
    this.list = this.events;
  },
  methods: {
    getAsyncData(searchName) {
      if (searchName == '') {
        this.list = this.events;
        return;
      }

      const name = searchName.toLowerCase();
      this.list = this.events.filter((event) => event.nombre.toLowerCase().includes(name));
    },
    handleSelect(selected) {
      if (selected == null) {
        this.search = '';
      } else {
        this.search = selected.nombre; // Mantener el nombre del evento seleccionado en el campo de búsqueda
      }

      this.onSelect(selected); // Llamar a la función pasada como prop
    },
  },
};
</script>
