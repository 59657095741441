import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import ui from './ui';
import events from './events';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    ui,
    events,
  },
});
