<template>
  <div v-if="event && event.id > 0" class="checkout-content">
    <div class="event-header">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <EventCard :event="event" />
          </div>
          <div class="column is-6">
            <div class="event-info">
              <h2 class="title">{{ event.nombre }}</h2>
              <h2 class="subtitle">{{ event.subtitulo }}</h2>
              <!-- <h3 class="subtitle">subtitulo</h3> -->
              <h3 class="theater has-text-link">{{ event.teatro }}</h3>
              <div class="columns is-multiline">
                <div class="column is-6">
                  <div class="info-label">{{ translatedText('category') }}</div>
                  <div class="info-value">{{ event.categoria }}</div>
                </div>
                <div v-if="event.presenta != ''" class="column is-6">
                  <div class="info-label">{{ translatedText('present') }}</div>
                  <div class="info-value">{{ event.presenta }}</div>
                </div>
                <div class="column is-6">
                  <div class="info-label">{{ translatedText('duration') }}</div>
                  <div class="info-value">{{ event.duracion }}</div>
                </div>
                <div v-if="event.direccion != ''" class="column is-6">
                  <div class="info-label">{{ translatedText('address') }}</div>
                  <div class="info-value">{{ event.direccion }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <div class="event-info">
              <h2 class="subtitle-sm info has-text-white"><b>Sinopsis:</b></h2>
              <p class="has-text-white">{{ event.sinopsis }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="event-information">
      <div class="container">
        <FormCheckout :key="formKey" @resetForm="handleResetForm"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import 'moment/locale/es';
import FormCheckout from './form.vue';
import EventCard from './event-card.vue';
export default {
  name: 'Checkout',
  components: {
    FormCheckout,
    EventCard,
  },
  data() {
    return {
      is_loading: true,
      formKey: 0,
    };
  },
  computed: {
    ...mapState({
      event: (state) => state.events.event,
    }),
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setData({ key: 'event', data: [] });
    this.loadEvent();
  },
  methods: {
    ...mapMutations({
      setData: 'events/SET_DATA',
    }),
    ...mapActions({
      fetchEvent: 'events/fetchEvent',
    }),
    async loadEvent() {
      await this.fetchEvent({
        funcionId: this.$route.params.id,
      });
    },
    handleResetForm() {
      this.formKey += 1;
    },
  },
};
</script>