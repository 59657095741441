import LayoutTaquilla from '@/layouts/taquilla';

import ModuleEvents from '@/modules/taquilla/events';
import ModuleCheckout from '@/modules/taquilla/checkout';

export default [
  {
    path: '/',
    component: LayoutTaquilla,
    children: [
      {
        path: '',
        component: ModuleEvents,
        name: 'taquilla',
      },
      {
        path: 'eventos',
        component: ModuleEvents,
        name: 'events',
      },
      {
        path: 'checkout/:id',
        component: ModuleCheckout,
        name: 'checkout',
      },
    ]
  },
];
