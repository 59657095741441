<template>
  <div class="header">
    <div class="container is-widescreen">
      <div class="columns">
        <div class="column">
          <b-navbar transparent :close-on-click="true" centered>
            <template #brand>
              <router-link :to="{ name: 'events' }" class="navbar-item has-text-white is-hidden-touch">Taquilla</router-link>

              <b-navbar-item tag="router-link" :to="{ name: 'events' }" class="is-hidden-desktop">
                <img src="https://voyalteatro.com/backend/api/img/render/10235" />
              </b-navbar-item>
            </template>
            <template #start>
              <b-navbar-item tag="router-link" :to="{ name: 'events' }" class="is-hidden-touch">
                <img src="https://voyalteatro.com/backend/api/img/render/10235" />
              </b-navbar-item>

              <router-link :to="{ name: 'events' }" class="navbar-item has-text-white is-hidden-desktop">Taquilla</router-link>
            </template>
            <template #end>
              <b-navbar-item tag="div">
                <div class="buttons">
                  <b-button @click="logout" type="is-primary">Cerrar sesión <i class="fa fa-sign-out" aria-hidden="true"></i></b-button>
                </div>
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'Header',
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  methods: {
    ...mapMutations({
      setData: 'auth/SET_DATA',
    }),
    logout() {
      Swal.fire({
        icon: 'info',
        title: 'Tu sesión ha sido cerrada correctamente.',
        confirmButtonColor: '#268af7',
      });

      localStorage.removeItem('adminToken');
      this.setData({ key: 'admin', data: [] });
      this.$router.push({ name: 'login' });
    },
  },
};
</script>