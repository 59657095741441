<script>
import { mapState } from 'vuex';
import loading from '@/components/common/loading';

export default {
  name: 'Root',
  components: {
    loading,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
    }),
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  watch: {},
  methods: {
    checkMobile() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 1023;
      this.$store.commit('ui/SET_DATA', { key: 'isMobile', data: isMobile });
    },
  },
};
</script>

<template>
  <div class="main-root">
    <main class="root-content">
      <router-view />
    </main>
    <loading></loading>
  </div>
</template>
