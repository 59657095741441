import LayoutLogin from '@/layouts/login';
import ModuleLogin from '@/modules/login';

export default [
  {
    path: '/login',
    component: LayoutLogin,
    children: [
      {
        path: '',
        component: ModuleLogin,
        name: 'login',
      },
    ]
  },
];
