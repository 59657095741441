<template>
  <section>
    <b-table
      :data="events"
      ref="table"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="id"
      :detail-transition="transitionName"
      :show-detail-icon="showDetailIcon"
    >
      <b-table-column field="nombre" label="Nombre" sortable v-slot="props">
        {{ props.row.nombre }}
      </b-table-column>

      <b-table-column field="teatro" label="Teatro" sortable v-slot="props">
        {{ props.row.teatro }}
      </b-table-column>

      <b-table-column label="Disponibilidad" v-slot="props">
        <span class="tag is-success" :class="props.row.disponibilidad" v-if="props.row.disponibilidad == 'disponible'"><b>Disponible</b></span>
        <span class="tag is-warning is-light" :class="props.row.disponibilidad" v-if="props.row.disponibilidad == 'ultimos'"><b>Últimos</b></span>
        <span class="tag is-danger" :class="props.row.disponibilidad" v-if="props.row.disponibilidad == 'agotado'"><b>Agotado</b></span>
      </b-table-column>

      <template #detail="rowProps">
        <div v-for="fecha in rowProps.row.fechas" :key="fecha.id">
          <b-table :data="fecha.funciones" ref="table-fechas" :narrowed="true">
            <b-table-column field="Fecha" label="Fecha" sortable width="80">
              <b-button size="is-small" :disabled="true" type="is-primary"> {{ fecha.fecha }}</b-button>
            </b-table-column>
            <b-table-column field="Funcion" label="Funcion" sortable width="80" v-slot="fechaProps">
              <b-button size="is-small" v-if="hasSellingPermission" type="is-success" tag="router-link" :to="{ name: 'checkout', params: { id: fechaProps.row.id } }"> {{ fechaProps.row.hora }}</b-button>
              <b-button size="is-small" v-else type="is-success" :disabled="true"> {{ fechaProps.row.hora }}</b-button>
            </b-table-column>
            <b-table-column field="Escaner" label="Escaner" sortable v-slot="fechaProps">
              <b-button size="is-small" type="is-info" tag="a" :href="getEscanerUrl(rowProps.row.codigo, fechaProps.row.id)" target="_blank"> Escaner</b-button>
            </b-table-column>
          </b-table>
        </div>
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
    };
  },
  computed: {
    ...mapState({
      admin: (state) => state.auth.admin,
    }),
    transitionName() {
      if (this.useTransition) {
        return 'fade';
      }
      return '';
    },
    hasSellingPermission() {
      const permisos = this.admin.permisos;
      if (!permisos || permisos.length === 0) {
        return false;
      }
      // Verificar si el único permiso es 'administradores_escaner'
      if (permisos.length === 1 && permisos[0].clave === 'administradores_escaner') {
        return false;
      }
      // Si tiene cualquier otro permiso, o más de un permiso
      return true;
    },
  },
  methods: {
    getEscanerUrl(code, date) {
      return `https://voyalteatro.com/check-in-tickets/${code}/${date}`;
    },
  },
};
</script>