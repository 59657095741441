<template>
  <div class="box" v-if="event && event.id > 0">
    <div class="event-form-section">
      <div class="container">
        <div class="columns">
          <div class="column is-12">
            <div class="event-buttons">
              <div class="buttons is-right">
                <b-button type="is-gray" tag="router-link" :to="{ name: 'events' }">Regresar</b-button>
                <b-button type="is-warning" tag="a" :href="getEscanerUrl(event.codigo, event.funcionId)" target="_blank"
                  >Escaner</b-button
                >
                <b-button type="is-warning" tag="a" :href="'https://voyalteatro.com/v.1.0.0/estadisticas/assistancetaq/' + event.id" target="_blank"
                  >Descargar reporte de ventas</b-button
                >
                <b-button type="is-warning" @click="(seatsnModalAforoSelect = true), seleccionarAsientosAforo()">Vender aforo</b-button>
                <b-button type="is-warning" @click="loadOrders()">Ver órdenes</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <h3 class="subtitle mt-5">
              <b>{{ formatDate(event.fecha, event.hora) }}</b>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="form-checkout-content">
      <!--       <pre>{{ event.ordenes }}</pre> -->

      <div class="modal print-tickets-modal" :class="{ 'is-active': showPrintTicketsModal }">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <h2 class="modal-card-title">Boletos</h2>
            <p class="modal-card-subtitle">Con los siguienes boletos podras entrar al evento</p>
            <!-- <b-button type="is-success" @click="printTickets()">Imprimir</b-button> -->
          </header>
          <section class="modal-card-body">
            <iframe :src="printTicketsModalUrl" ref="pdfIframe" id="iframeprint" frameborder="0"></iframe>
          </section>
          <footer class="modal-card-foot">
            <button @click="(showPrintTicketsModal = false), (printTicketsModalUrl = null)" class="delete" aria-label="close"></button>
          </footer>
        </div>
      </div>

      <div class="modal select-seats-modal" :class="{ 'is-active': seatsnModalSelect }">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ translatedText('select_seats') }}</p>
            <button @click="seatsnModalSelect = false" class="delete" aria-label="close"></button>
          </header>
          <section class="modal-card-body">
            <div class="columns is-multiline">
              <div class="column is-9">
                <div class="localities">
                  <ImageZoom v-if="seatsnModalSelect" :imageUrl="event.localidad" />
                </div>
              </div>
              <div class="column is-3">
                <div class="select-seats-list">
                  <div v-for="(boleto, index) in boletosPorAsignarAsientos" :key="index">
                    <b-field :label="translatedText('ticket') + (index + 1) + ' - ' + boleto.boleto" type="is-info">
                      <b-select
                        :id="'select-asiento-' + boleto.boletos_id + '-' + index"
                        :placeholder="translatedText('select_seat_placeholder')"
                        rounded
                        expanded
                        required
                        size="is-small"
                      >
                        <option v-for="asiento in filteredAsientos(boleto.asientos)" :key="asiento.id" :value="asiento.id">
                          {{ asiento.fila + ' - ' + asiento.columna }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button @click="guardarAsientos" class="button is-warning">Reservar</button>
          </footer>
        </div>
      </div>

      <div class="modal select-seats-aforo-modal" :class="{ 'is-active': seatsnModalAforoSelect }">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ translatedText('select_seats') }}</p>
            <button @click="seatsnModalAforoSelect = false" class="delete" aria-label="close"></button>
          </header>
          <section class="modal-card-body">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="localities">
                  <ImageZoom v-if="seatsnModalAforoSelect" :imageUrl="event.localidad" />
                </div>
              </div>
              <div class="column is-12">
                <div class="select-seats-list-aforo">
                  <div class="table-container">
                    <table class="table is-fullwidth is-bordered is-striped">
                      <thead>
                        <tr>
                          <th>Tipo de boleto</th>
                          <th>Inicio</th>
                          <th>Fin</th>
                          <th>Seleccionados</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(boleto, index) in boletosPorAsignarAsientosAforo" :key="boleto.id">
                          <td>{{ boleto.boleto }}</td>
                          <td>
                            <b-select
                              :id="'select-asiento-inicio-' + boleto.boletos_id + '-' + index"
                              :placeholder="translatedText('select_seat_placeholder')"
                              rounded
                              expanded
                              size="is-small"
                              @input="handleSeatSelectionInicio($event, index)"
                            >
                              <option v-for="asiento in filteredAsientos(boleto.asientos_disponibles)" :key="asiento.id" :value="asiento.id">
                                {{ asiento.fila + ' - ' + asiento.columna }}
                              </option>
                            </b-select>
                          </td>
                          <td>
                            <b-select
                              :id="'select-asiento-fin-' + boleto.boletos_id + '-' + index"
                              :placeholder="translatedText('select_seat_placeholder')"
                              rounded
                              expanded
                              size="is-small"
                              @input="handleSeatSelectionFin($event, index)"
                            >
                              <option v-for="asiento in filteredAsientos(boleto.asientos_disponibles)" :key="asiento.id" :value="asiento.id">
                                {{ asiento.fila + ' - ' + asiento.columna }}
                              </option>
                            </b-select>
                          </td>
                          <td>{{ boleto.asientos_seleccionados }}/{{ boleto.cantidad }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button :disabled="loadingFormBtn" @click="completarVentaAforo" class="button is-info">Vender</button>
          </footer>
        </div>
      </div>

      <div class="modal show-orders-modal" :class="{ 'is-active': showOrders }">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Ordenes</p>
            <button @click="showOrders = false" class="delete" aria-label="close"></button>
          </header>
          <section class="modal-card-body">
            <b-field>
              <b-input v-model="searchQuery" placeholder="Buscar..." type="search" icon="magnify"> </b-input>
            </b-field>
            <b-table
              :data="filteredOrders"
              ref="table"
              :opened-detailed="defaultOpenedDetails"
              detailed
              detail-key="id"
              :detail-transition="transitionName"
              :show-detail-icon="showDetailIcon"
              paginated
              :per-page="10"
            >
              <b-table-column field="comprador" label="Comprador / Vendedor" sortable v-slot="props">
                <p v-if="props.row.usuario_nombre">
                  {{ props.row.usuario_nombre }}
                  {{ props.row.usuario_email }}
                </p>
                <p v-else>
                  {{ props.row.admin_nombre }}
                  {{ props.row.admin_email }}
                </p>
              </b-table-column>

              <b-table-column field="fecha" label="Fecha" sortable v-slot="props" width="200">
                {{ formatDate(props.row.fecha) }}
              </b-table-column>

              <b-table-column field="correo" label="Correo" sortable v-slot="props">
                {{ props.row.usuario_email }}
                {{ props.row.emailCC }}
              </b-table-column>

              <b-table-column field="codigo" label="Código" sortable v-slot="props">
                {{ props.row.code }}
              </b-table-column>

              <b-table-column field="total_boletos" label="Total de boletos" sortable v-slot="props">
                {{ props.row.boletos.length }}
              </b-table-column>

              <b-table-column field="origen" label="Origen" sortable v-slot="props">
                {{ props.row.origen }}
              </b-table-column>

              <b-table-column field="tipo_pago" label="Tipo de pago" sortable v-slot="props">
                {{ props.row.tipo_pago }}
              </b-table-column>

              <b-table-column field="payment_id" label="Compra" sortable v-slot="props">
                {{ props.row.payment_id }}
              </b-table-column>

              <b-table-column label="Acciones" v-slot="props">
                <b-dropdown aria-role="list" :position="'is-bottom-left'">
                  <template #trigger="{ active }">
                    <b-button size="is-small" type="is-dark" :icon-right="active ? 'menu-up' : 'menu-down'" />
                  </template>
                  <b-dropdown-item v-if="props.row.emailCC != null || props.row.usuarios_id != null" aria-role="listitem" :data-id="props.row.origen">
                    <b-button size="is-small" type="is-info" expanded @click="resendEmail(props.row)">Reenviar correo</b-button>
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" :data-id="props.row.origen">
                    <b-button size="is-small" tag="a" :href="reportsUrl + props.row.id + '/orden'" target="_blank" type="is-success" expanded
                      >Re-imprimir orden completa</b-button
                    >
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" :data-id="props.row.origen">
                    <b-button size="is-small" tag="a" :href="reportsUrl + props.row.id + '/separado'" target="_blank" type="is-warning" expanded
                      >Re-imprimir orden separada</b-button
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </b-table-column>

              <template #detail="props">
                <h2>
                  <b>Detalles de orden: #{{ props.row.id }} </b>
                </h2>
                <b-table :data="props.row.boletos" ref="table-fechas" :narrowed="true">
                  <b-table-column field="boleto" label="Boleto" sortable v-slot="propsdetail">
                    {{ propsdetail.row.boleto }}
                  </b-table-column>
                  <b-table-column field="codigo" label="Codigo" sortable v-slot="propsdetail">
                    {{ propsdetail.row.codigo }}
                  </b-table-column>
                  <b-table-column field="fila" label="Fila" sortable v-slot="propsdetail">
                    {{ propsdetail.row.fila }}
                  </b-table-column>
                  <b-table-column field="columna" label="Columna" sortable v-slot="propsdetail">
                    {{ propsdetail.row.columna }}
                  </b-table-column>

                  <b-table-column field="estatus" label="Estatus" v-slot="propsdetail">
                    <b-tag v-if="propsdetail.row.estatus == 'INGRESADO'" type="is-success" rounded>INGRESADO</b-tag>
                    <b-tag v-else type="is-danger" rounded>NO INGRESADO</b-tag>
                  </b-table-column>

                  <b-table-column label="Acciones" v-slot="propsdetail">
                    <b-dropdown aria-role="list" :position="'is-bottom-left'">
                      <template #trigger="{ active }">
                        <b-button size="is-small" type="is-dark" :icon-right="active ? 'menu-up' : 'menu-down'" />
                      </template>
                      <b-dropdown-item aria-role="listitem">
                        <b-button
                          size="is-small"
                          type="is-success"
                          tag="a"
                          :href="reportsUrl + props.row.id + '/separado/' + propsdetail.row.id"
                          target="_blank"
                          expanded
                          >Re-imprimir boleto</b-button
                        >
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-table-column>
                </b-table>
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot"></footer>
        </div>
      </div>

      <div class="form-checkout-header">
        <div class="form-checkout-table-information">
          <div class="container">
            <div class="columns">
              <div class="column is-10">Aforo del evento</div>
              <div class="column is-2 has-text-centered">{{ event.aforo }}</div>
            </div>
            <br />
            <div class="columns">
              <div class="column is-10">Boletos disponibles para este evento</div>
              <div class="column is-2 has-text-centered">{{ event.aforo - boletosVendidos }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-checkout-seleccionar-cantidad-boletos">
        <div class="container">
          <div class="columns">
            <div class="column no-padding">
              <div class="table-container">
                <table class="table is-fullwidth is-bordered is-striped">
                  <thead>
                    <tr>
                      <th>Tipo de boleto</th>
                      <th>Disponibles</th>
                      <th>Vendidos</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="boleto in event.boletos" :key="boleto.id">
                      <td>{{ boleto.nombre }}</td>
                      <td v-if="boleto.limite == 0">{{ boleto.cantidad - boleto.vendidos }}</td>
                      <td v-else>Sin límite</td>
                      <td>{{ boleto.vendidos }}</td>
                      <td>${{ boleto.precio }}.00</td>
                      <td class="inputTickets">
                        <b-field>
                          <b-numberinput
                            size="is-small"
                            :value="getCantidadBoletosSeleccionados(boleto.id)"
                            min="0"
                            :editable="false"
                            @input="handleCantidadBoletosChange(boleto, $event)"
                          ></b-numberinput>
                        </b-field>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Total</b></td>
                      <td>
                        <b>{{ event.aforo - boletosVendidos }}</b>
                      </td>
                      <td>
                        <b>{{ boletosVendidos }}</b>
                      </td>
                      <td colspan="2">
                        <b>${{ total }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-checkout-adicionales has-text-centered">
        <div class="container">
          <div class="columns">
            <div class="column">
              <div class="switch-espectador">
                <b-field>
                  <b-switch type="is-info" v-model="adicionales.isEf">Espectador Frecuente</b-switch>
                </b-field>

                <div v-if="adicionales.isEf" class="form-checkout-adicionales-actions">
                  <b-input v-model="adicionales.efNumber" placeholder="Numero de Espectador" expanded></b-input>
                  <b-button type="is-primary" size="is-small" expanded @click="applyEf()">Agregar</b-button>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="switch-cupon">
                <b-field>
                  <b-switch type="is-info" v-model="adicionales.hasCupon">Cupón de descuento</b-switch>
                </b-field>

                <div v-if="adicionales.hasCupon" class="form-checkout-adicionales-actions">
                  <b-input v-model="adicionales.cuponCode" placeholder="Código" expanded></b-input>
                  <b-button type="is-primary" size="is-small" expanded @click="applyCupon()">Agregar</b-button>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="switch-creditpay">
                <b-field>
                  <b-switch type="is-info" v-model="adicionales.hasCreditpay">Pago con tarjeta</b-switch>
                </b-field>

                <div v-if="adicionales.hasCreditpay" class="form-checkout-adicionales-actions">
                  <b-input v-model="adicionales.creditPayCode" :maxlength="7" placeholder="Código de la terminal" expanded></b-input>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="switch-email">
                <b-field>
                  <b-switch type="is-info" v-model="adicionales.hasEmail">Enviar por correo</b-switch>
                </b-field>

                <div v-if="adicionales.hasEmail" class="form-checkout-adicionales-actions">
                  <b-input type="email" v-model="adicionales.userEmail" placeholder="Correo" expanded></b-input>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="switch-print">
                <b-field>
                  <b-switch type="is-info" v-model="adicionales.printSeparate">Imprimir por separado</b-switch>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-checkout-submit">
        <b-button v-if="boletosSeleccionados.length > 0 && !orderConAsientos" type="is-info" :disabled="loadingFormBtn" @click="completarPago()"
          >Vender</b-button
        >
        <b-button
          v-if="boletosSeleccionados.length > 0 && orderConAsientos"
          type="is-warning"
          @click="(seatsnModalSelect = true), seleccionarAsientos()"
          >Seleccionar Asientos</b-button
        >
        <b-button
          class="mt-5"
          v-if="boletosSeleccionados.length > 0 && orderConAsientos && totalBoletosConAsientos == asientosSeleccionados.length"
          type="is-info"
          :disabled="loadingFormBtn"
          @click="completarPago()"
          >Vender</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ImageZoom from '../../../components/common/imagezoom.vue';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  name: 'FormCheckout',
  components: {
    ImageZoom,
  },
  data() {
    return {
      searchQuery: '',
      is_loading: true,
      showPrintTicketsModal: false,
      seatsnModalSelect: false,
      seatsnModalAforoSelect: false,
      printTicketsModalUrl: null,
      orden: {},
      boletosDisponiblesEvento: 0,
      boletosSeleccionados: [],
      loadingFormBtn: false,
      boletosPorAsignarAsientos: [],
      boletosPorAsignarAsientosAforo: [],
      asientosSeleccionados: [],
      showOrders: false,
      adicionales: {
        isEf: false,
        efNumber: '',
        hasCupon: false,
        cuponCode: null,
        hasCreditpay: false,
        creditPayCode: null,
        printSeparate: false,
        hasEmail: false,
        userEmail: null,
      },
      cupon: null,
      espectador: null,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: false,
      reportsUrl: process.env.VUE_APP_BACKEND_URL + 'reportes/tickets/',
    };
  },
  computed: {
    ...mapState({
      event: (state) => state.events.event,
    }),
    filteredOrders() {
      if (!this.searchQuery) {
        return this.event.ordenes;
      }
      const query = this.searchQuery.toLowerCase();
      return this.event.ordenes.filter((order) => {
        return (
          (order.usuario_nombre?.toLowerCase() || '').includes(query) ||
          (order.usuario_email?.toLowerCase() || '').includes(query) ||
          (order.admin_nombre?.toLowerCase() || '').includes(query) ||
          (order.admin_email?.toLowerCase() || '').includes(query) ||
          (order.code?.toLowerCase() || '').includes(query) ||
          (order.origen?.toLowerCase() || '').includes(query) ||
          (order.tipo_pago?.toLowerCase() || '').includes(query) ||
          (order.payment_id?.toLowerCase() || '').includes(query) ||
          order.boletos.some(
            (boleto) =>
              (boleto.boleto?.toLowerCase() || '').includes(query) ||
              (boleto.codigo?.toLowerCase() || '').includes(query) ||
              (boleto.fila?.toLowerCase() || '').includes(query) ||
              (boleto.columna?.toLowerCase() || '').includes(query),
          )
        );
      });
    },
    transitionName() {
      if (this.useTransition) {
        return 'fade';
      }
      return '';
    },
    boletosVendidos() {
      return this.event.boletos.reduce((total, boleto) => {
        return total + parseInt(boleto.vendidos, 10);
      }, 0);
    },
    orderConAsientos() {
      return this.boletosSeleccionados.some((boleto) => boleto.tiene_asientos);
    },
    comisiones() {
      let comision_pago_tarjeta = 0.05;
      let comisiones = 0;
      let subtotal = this.boletosSeleccionados.reduce((acc, boleto) => acc + boleto.cantidad * boleto.precio, 0);
      let descuentos = parseFloat(this.descuento);

      let total_sin_comisiones = subtotal - descuentos;

      if (total_sin_comisiones > 0 && this.adicionales.hasCreditpay) {
        comisiones = total_sin_comisiones * comision_pago_tarjeta;
      }

      return comisiones.toFixed(2);
    },
    total() {
      let subtotal = this.boletosSeleccionados.reduce((acc, boleto) => acc + boleto.cantidad * boleto.precio, 0);
      let descuento = parseFloat(this.descuento);
      let comisiones = parseFloat(this.comisiones);

      subtotal -= descuento;

      subtotal += comisiones;

      subtotal = Math.max(0, subtotal);

      return subtotal.toFixed(2);
    },
    descuento() {
      let subtotal = this.boletosSeleccionados.reduce((acc, boleto) => acc + boleto.cantidad * boleto.precio, 0);
      // Verificar si hay un cupón activo
      if (this.cupon && this.cupon.activar == '1') {
        if (this.cupon.tipo == '0') {
          // Si el tipo de cupón es neto (tipo 0)
          if (this.cupon.por_boleto == 1) {
            const cantidad_boletos = this.boletosSeleccionados.reduce((total, boleto) => total + boleto.cantidad, 0);
            return parseFloat(this.cupon.descuento) * cantidad_boletos;
          } else {
            // Calcular el descuento neto
            return parseFloat(this.cupon.descuento);
          }
        } else if (this.cupon.tipo == '1') {
          // Si el tipo de cupón es porcentaje (tipo 1)
          return (parseFloat(subtotal) * parseFloat(this.cupon.descuento)) / 100;
        }
      }
      // Si no hay cupón activo o no es aplicable, el descuento es cero
      return 0;
    },
    filteredAsientos() {
      return (asientos) => {
        return asientos.filter((asiento) => asiento.bloqueado == 0);
      };
    },
    totalBoletosConAsientos() {
      return this.boletosSeleccionados.reduce((total, boleto) => {
        if (boleto.tiene_asientos) {
          return total + boleto.cantidad;
        } else {
          return total;
        }
      }, 0);
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      fetchEvent: 'events/fetchEvent',
      submitOrder: 'events/submitOrder',
      setPLaces: 'events/setPLaces',
      fetchCupon: 'events/fetchCupon',
      fetchEfNumber: 'events/fetchEfNumber',
      sendTickets: 'events/sendTickets',
    }),
    async loadOrders() {
      await this.loadEvent();
      this.showOrders = true;
    },
    async handleCantidadBoletosChange(boleto, cantidad) {
      // Buscar si ya existe un boleto con el mismo ID en el arreglo de boletos seleccionados
      const index = this.boletosSeleccionados.findIndex((item) => item.boletos_id === boleto.id);

      if (cantidad > 0) {
        // Si la cantidad es mayor que 0, agregar o actualizar el boleto en el arreglo de boletos seleccionados
        if (index !== -1) {
          // Si ya existe un boleto con el mismo ID, actualizar su cantidad
          this.boletosSeleccionados[index].cantidad = cantidad;
        } else {
          // Si no existe, agregar un nuevo elemento al arreglo
          const boletoSeleccionadoData = {
            cantidad: cantidad,
            boletos_id: boleto.id,
            boleto: boleto.nombre,
            precio: boleto.precio,
            espectador: boleto.espectador,
            estatus: 0,
            asientos_disponibles: boleto.asientos,
            asientos: [],
            tiene_asientos: boleto.tiene_asientos,
          };

          this.boletosSeleccionados.push(boletoSeleccionadoData);
        }
      } else {
        // Si la cantidad es 0 o menor, eliminar el boleto del arreglo de boletos seleccionados
        if (index !== -1) {
          this.boletosSeleccionados.splice(index, 1);
        }
      }

      const boletosEspectador = this.boletosSeleccionados.find((boleto) => boleto.espectador == 1);

      if (boletosEspectador && this.espectador == null) {
        Swal.fire({
          icon: 'info',
          title: 'Para comprar boletos con esta tarifa preferencial, debes adquirir primero tu membresía Espectador Frecuente.',
          confirmButtonColor: '#268af7',
        });

        await this.clearForm();
        this.$emit('resetForm');
        return;
      }
    },
    getCantidadBoletosSeleccionados(boletoId) {
      const filterBoletosSeleccionados = this.boletosSeleccionados.filter((boleto) => boleto.boletos_id === boletoId);

      if (filterBoletosSeleccionados.length > 0) {
        return filterBoletosSeleccionados.reduce((total, boleto) => total + boleto.cantidad, 0);
      } else {
        return 0;
      }
    },
    seleccionarAsientos() {
      //crear arreglo de asiento que quiero seleccionar
      this.boletosPorAsignarAsientos = [];

      this.boletosSeleccionados.forEach((boletoSeleccionado) => {
        if (boletoSeleccionado.tiene_asientos) {
          for (let i = 0; i < boletoSeleccionado.cantidad; i++) {
            const nuevoBoleto = {
              cantidad: 1,
              boletos_id: boletoSeleccionado.boletos_id,
              boleto: boletoSeleccionado.boleto,
              precio: boletoSeleccionado.precio,
              estatus: boletoSeleccionado.estatus,
              asientos: boletoSeleccionado.asientos_disponibles,
            };

            this.boletosPorAsignarAsientos.push(nuevoBoleto);
          }
        }
      });
    },
    seleccionarAsientosAforo() {
      this.boletosPorAsignarAsientosAforo = [];

      // Crear arreglo de asientos que quiero seleccionar
      this.boletosPorAsignarAsientosAforo = this.boletosSeleccionados
        .filter((boletoSeleccionado) => boletoSeleccionado.tiene_asientos)
        .map((boletoSeleccionado) => ({
          cantidad: boletoSeleccionado.cantidad,
          boletos_id: boletoSeleccionado.boletos_id,
          boleto: boletoSeleccionado.boleto,
          precio: boletoSeleccionado.precio,
          estatus: boletoSeleccionado.estatus,
          asientos: [],
          asientos_disponibles: boletoSeleccionado.asientos_disponibles,
          asientos_seleccionados: 0,
          asientos_inicio: null,
          asientos_fin: null,
        }));
    },
    handleSeatSelectionInicio(event, index) {
      this.boletosPorAsignarAsientosAforo[index].asientos_inicio = event;
      this.contarSeatsSelecionadosPorBoleto(index);
    },
    handleSeatSelectionFin(event, index) {
      this.boletosPorAsignarAsientosAforo[index].asientos_fin = event;
      this.contarSeatsSelecionadosPorBoleto(index);
    },
    contarSeatsSelecionadosPorBoleto(index) {
      const boleto = this.boletosPorAsignarAsientosAforo[index];

      if (boleto.asientos_inicio && boleto.asientos_fin) {
        const inicioIndex = boleto.asientos_disponibles.findIndex((asiento) => asiento.id === boleto.asientos_inicio);
        const finIndex = boleto.asientos_disponibles.findIndex((asiento) => asiento.id === boleto.asientos_fin);

        if (inicioIndex !== -1 && finIndex !== -1) {
          if (inicioIndex <= finIndex) {
            const asientosSeleccionados = boleto.asientos_disponibles.slice(inicioIndex, finIndex + 1);
            const cantidadSeleccionada = asientosSeleccionados.length;

            // Actualiza reactivamente las propiedades del boleto usando this.$set
            this.$set(boleto, 'asientos_seleccionados', cantidadSeleccionada);
            this.$set(boleto, 'asientos', asientosSeleccionados);

            // Actualiza el array boletosPorAsignarAsientosAforo en la posición index
            this.$set(this.boletosPorAsignarAsientosAforo, index, boleto);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'El asiento de fin no puede ser mayor que el de inicio.',
              confirmButtonColor: '#268af7',
            });

            boleto.asientos_seleccionados = 0;
            this.$set(this.boletosPorAsignarAsientosAforo, index, boleto);
          }
        } else {
          console.error('No se encontraron los asientos de inicio o fin en asientos_disponibles.');
        }
      }
    },
    async guardarAsientos() {
      Swal.fire({
        icon: 'info',
        title: this.translatedText('loading'),
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
      });

      const selects = this.boletosPorAsignarAsientos.map((boleto, index) => {
        const boletoId = boleto.boletos_id;
        return document.querySelector(`#select-asiento-${boletoId}-${index}`);
      });

      const asientosSeleccionadosArray = [];
      this.asientosSeleccionados = [];

      this.boletosPorAsignarAsientos.forEach((boleto, index) => {
        const asientoSeleccionado = boleto.asientos.find((asiento) => asiento.id === selects[index].value);

        if (asientoSeleccionado) {
          asientosSeleccionadosArray.push({ boletoId: boleto.boletos_id, asientoSeleccionado });
          this.asientosSeleccionados.push(asientoSeleccionado);
        }
      });

      if (this.asientosSeleccionados.length != this.totalBoletosConAsientos) {
        Swal.close();

        Swal.fire({
          icon: 'info',
          title: this.translatedText('seat_selection_title'),
          showConfirmButton: true,
          confirmButtonText: this.translatedText('confirm_button_text'),
          confirmButtonColor: '#268af7',
        });

        return;
      }

      const asientosRepetidos = this.asientosSeleccionados.filter((asiento, index, self) => self.findIndex((a) => a.id === asiento.id) !== index);

      if (asientosRepetidos.length > 0) {
        const asientosRepetidosTexto = asientosRepetidos.map((asiento) => `${asiento.fila}-${asiento.columna}`).join(', ');
        Swal.close();

        Swal.fire({
          icon: 'info',
          title: this.translatedText('duplicate_seats_title') + `${asientosRepetidosTexto}`,
          showConfirmButton: true,
          confirmButtonText: this.translatedText('confirm_button_text'),
          confirmButtonColor: '#268af7',
        });

        this.asientosSeleccionados = [];
        return;
      }

      const reserve = await this.reservePlaces(this.asientosSeleccionados);

      if (reserve && !reserve.status) {
        await this.loadEvent();
        Swal.close();

        Swal.fire({
          icon: reserve.class,
          title: this.translatedText('seat_reservation_title'),
          text: this.translatedText(reserve.code),
          confirmButtonColor: '#268af7',
        });

        return;
      }

      // Agrupar los asientos seleccionados por boletoId
      const asientosPorBoleto = {};
      asientosSeleccionadosArray.forEach(({ boletoId, asientoSeleccionado }) => {
        if (!asientosPorBoleto[boletoId]) {
          asientosPorBoleto[boletoId] = [];
        }
        asientosPorBoleto[boletoId].push(asientoSeleccionado);
      });

      // Asignar los asientos seleccionados a los boletos seleccionados
      this.boletosSeleccionados.forEach((boleto) => {
        const asientos = asientosPorBoleto[boleto.boletos_id];
        if (asientos) {
          boleto.asientos = asientos;
        }
      });

      Swal.close();

      Swal.fire({
        icon: reserve.class,
        title: this.translatedText('seat_reservation_title'),
        text: this.translatedText(reserve.code),
        confirmButtonColor: '#268af7',
      });

      this.seatsnModalSelect = false;
      this.boletosPorAsignarAsientos = [];
    },
    async reservePlaces(places) {
      return await this.setPLaces({ asientos: places });
    },
    async completarPago() {
      this.loadingFormBtn = true;

      let paymentType = 'efectivo';

      if (this.total <= 0) {
        paymentType = 'sin costo';
      } else if (this.adicionales.hasCreditpay) {
        paymentType = 'tarjeta';
      }

      const charge_info = {
        type: 'events',
        boletos: this.boletosSeleccionados,
        total: this.total,
        subtotal: this.total,
        comisiones: this.comisiones,
        descuento: this.descuento,
        cupon: null,
        ef: null,
        emailCC: null,
        evento_id: this.event.id,
        fecha_id: this.event.fechaId,
        funcion_id: this.event.funcionId,
        evento: this.event.nombre,
        paymentType,
        asientos: this.event.asientos,
        taquilla: true,
        creditPayCode: null,
        impresionSeparada: this.adicionales.printSeparate,
      };

      if (this.adicionales.hasCupon) {
        if (this.cupon == null) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor asigna un cupon valido.',
            confirmButtonColor: '#268af7',
          });

          this.loadingFormBtn = false;
          return;
        }

        charge_info.cupon = this.cupon;
      }

      if (this.adicionales.isEf) {
        if (this.espectador == null) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor ingresa un numero de espectador valido.',
            confirmButtonColor: '#268af7',
          });

          this.loadingFormBtn = false;
          return;
        }

        charge_info.ef = this.espectador;
      }

      if (this.adicionales.hasCreditpay) {
        if (this.adicionales.creditPayCode == null || this.adicionales.creditPayCode.length != 7) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor ingresa un código de terminal completo.',
            confirmButtonColor: '#268af7',
          });

          this.loadingFormBtn = false;
          return;
        }

        charge_info.creditPayCode = this.adicionales.creditPayCode;
      }

      if (this.adicionales.hasEmail) {
        if (!this.adicionales.userEmail || !this.validarCorreo(this.adicionales.userEmail)) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor ingresa un correo válido.',
            confirmButtonColor: '#268af7',
          });

          this.loadingFormBtn = false; // Detener la carga del botón u otro estado de carga
          return;
        }

        // Continuar con la lógica si el correo es válido
        charge_info.emailCC = this.adicionales.userEmail;
      }

      await this.generateOrder(charge_info);
    },
    async generateOrder(charge_info) {
      try {
        Swal.fire({
          icon: 'info',
          title: 'Cargando...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        const orderConfirmation = await this.submitOrder(charge_info);

        Swal.close();
        this.loadingFormBtn = false;

        if (orderConfirmation && orderConfirmation.status) {
          if (charge_info.emailCC != '' && charge_info.emailCC != null) {
            Swal.fire({
              icon: 'success',
              title: 'Boletos enviados',
              text: 'Boletos enviados a: ' + charge_info.emailCC,
              confirmButtonColor: '#268af7',
            });
          } else {
            this.printTicketsModalUrl = orderConfirmation.payload.order.url;
            this.showPrintTicketsModal = true;
          }

          this.clearForm();
        } else {
          let text_error = this.translatedText(orderConfirmation.code);

          if (orderConfirmation.code == 'pv406') {
            text_error = this.translatedText(orderConfirmation.code) + orderConfirmation.data + this.translatedText('pv406_2');
          }

          if (orderConfirmation.code == 'pv409') {
            text_error = this.translatedText(orderConfirmation.code) + orderConfirmation.data + this.translatedText('pv409_2');
          }

          Swal.fire({
            icon: 'error',
            title: this.translatedText('order_error_title'),
            text: text_error,
            confirmButtonColor: '#268af7',
          });
        }
      } catch (error) {
        Swal.close();
        this.loadingFormBtn = false;
        console.error('Error al generar la orden:', error);
      }
    },
    async clearForm() {
      this.seatsnModalSelect = false;
      this.seatsnModalAforoSelect = false;
      this.orden = {};
      this.boletosDisponiblesEvento = 0;
      this.boletosSeleccionados = [];
      this.loadingFormBtn = false;
      this.boletosPorAsignarAsientos = [];
      this.boletosPorAsignarAsientosAforo = [];
      this.asientosSeleccionados = [];
      this.adicionales = {
        isEf: false,
        efNumber: '',
        hasCupon: false,
        cuponCode: null,
        hasCreditpay: false,
        creditPayCode: null,
        printSeparate: false,
        sendByEmail: false,
      };
      this.cupon = null;
      this.espectador = null;

      await this.loadEvent();
    },
    printTickets() {
      var printFrame = document.getElementById('iframeprint');
      printFrame.contentWindow.print();
    },
    async loadEvent() {
      await this.fetchEvent({
        funcionId: this.event.funcionId,
      });
    },
    async applyCupon() {
      if (this.adicionales.cuponCode.trim() !== '') {
        Swal.fire({
          icon: 'info',
          title: this.translatedText('coupon_validation_title'),
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        this.cupon = null;

        const usuarios_id = this.espectador && this.espectador.id ? this.espectador.id : null;

        const payload_cupon = await this.fetchCupon({
          nombre: this.adicionales.cuponCode,
          funciones_id: this.event.funcionId,
          usuarios_id: usuarios_id,
        });

        if (payload_cupon.status) {
          Swal.fire({
            icon: 'success',
            title: this.translatedText(payload_cupon.code),
            confirmButtonColor: '#268af7',
          });

          this.cupon = payload_cupon.payload.cupon;
        } else {
          Swal.fire({
            icon: 'error',
            title: this.translatedText(payload_cupon.code),
            confirmButtonColor: '#268af7',
          });

          this.adicionales.cuponCode = null;
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: this.translatedText('empty_coupon_field_title'),
          text: this.translatedText('invalid_coupon_text'),
          confirmButtonColor: '#268af7',
        });
      }
    },
    async applyEf() {
      if (this.adicionales.efNumber.trim() !== '') {
        Swal.fire({
          icon: 'info',
          title: 'Validando...',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        this.espectador = null;

        const payload_espectador = await this.fetchEfNumber({
          tef: this.adicionales.efNumber,
        });

        if (payload_espectador.status) {
          Swal.fire({
            icon: 'success',
            title: payload_espectador.msg,
            confirmButtonColor: '#268af7',
          });

          this.espectador = payload_espectador.payload;
        } else {
          Swal.fire({
            icon: 'error',
            title: payload_espectador.msg,
            confirmButtonColor: '#268af7',
          });

          this.adicionales.efNumber = null;
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Campo de espectador frecuente vacío',
          text: 'Por favor, ingrese el numero de espectador.',
          confirmButtonColor: '#268af7',
        });
      }
    },
    formatDate(fecha, hora = null) {
      let datetime;
      if (hora) {
        // Combina la fecha y la hora en un solo objeto moment
        datetime = moment(`${fecha} ${hora}`, 'YYYY-MM-DD HH:mm');
      } else {
        // Usa solo la fecha
        datetime = moment(fecha, 'YYYY-MM-DD HH:mm:ss');
      }
      // Formatea la fecha y la hora
      const formattedDate = datetime.locale('es').format('dddd, D MMMM YYYY, [a las] h:mm a');
      // Capitaliza la primera letra
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },
    async completarVentaAforo() {
      if (this.boletosPorAsignarAsientosAforo.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No hay boletos para completar la venta.',
          confirmButtonColor: '#268af7',
        });
        return; // Salir de la función si no hay boletos
      }

      // Validar que todos los boletos en boletosPorAsignarAsientosAforo tengan los asientos seleccionados
      const todosCompletos = this.boletosPorAsignarAsientosAforo.every((boleto) => {
        return boleto.asientos_seleccionados == boleto.cantidad && boleto.asientos.length == boleto.cantidad;
      });

      if (!todosCompletos) {
        // Mostrar mensaje de error si no todos los boletos tienen los asientos seleccionados
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Selecciona todos los asientos.',
          confirmButtonColor: '#268af7',
        });
        return; // Salir de la función si hay boletos incompletos
      }

      // Agregar los asientos de boletosPorAsignarAsientosAforo a boletosSeleccionados
      this.boletosPorAsignarAsientosAforo.forEach((boleto) => {
        const index = this.boletosSeleccionados.findIndex((sel) => sel.boletos_id == boleto.boletos_id);
        if (index !== -1) {
          this.$set(this.boletosSeleccionados[index], 'asientos', boleto.asientos);
        }
      });

      // Completar el pago después de haber agregado los asientos seleccionados
      await this.completarPago();
    },
    validarCorreo(correo) {
      // Expresión regular para validar formato de correo electrónico
      const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regexCorreo.test(correo);
    },
    async resendEmail(order) {
      const emailValues = {
        order: order.id,
      };

      if (order.usuarios_id !== null) {
        emailValues.user.email = order.usuario_email;
        emailValues.user.nombre = order.usuario_nombre;
      } else {
        emailValues.email = order.emailCC;
      }

      const response = await this.sendTickets(emailValues);

      if (response.status) {
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: 'Correo reenviado exitosamente.',
          confirmButtonColor: '#268af7',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error al re-enviar el correo.',
          confirmButtonColor: '#268af7',
        });
      }
    },
    getEscanerUrl(code, date) {
      return `https://voyalteatro.com/check-in-tickets/${code}/${date}`;
    },
  },
};
</script>