import axiosInstance from '../../api/axiosInstance';

const actions = {
  async fetchEvents({ commit }, orderData) {
    try {
      const url = '/taquilla/events';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status === 200 && response?.data?.payload) {
        const events = response.data.payload.eventos;

        if (events.length == 0) {
          commit('INIT_STATE');
        }
        commit('SET_DATA', { key: 'list', data: events || [] });
      } else {
        throw new Error('No se pudo obtener la información de la taquilla');
      }
    } catch (error) {
      console.error('Error al obtener los eventos:', error);
    }
  },
  async fetchEvent({ commit }, orderData) {
    try {
      const url = '/taquilla/event';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status === 200 && response?.data?.payload) {
        const event = response.data.payload;

        if (event) {
          commit('SET_DATA', { key: 'event', data: event || [] });
        }

      } else {
        throw new Error('No se pudo obtener la información de la taquilla');
      }
    } catch (error) {
      console.error('Error al obtener los eventos:', error);
    }
  },
  async submitOrder({ commit }, orderData) {
    try {
      const url = '/taquilla/orden';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status === 200) {
        const data = response.data;

        if (data.status) {
          const order = response.data.payload.order;
          commit('SET_DATA', { key: 'order', data: order || false });
        }

        return data;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error(error);
      return false; // Devolver false en caso de error
    }
  },
  async setPLaces({ commit }, places) {
    try {

      console.log(places);

      const url = '/asientos/reserve';
      const response = await axiosInstance.post(url, places);
      commit('SET_DATA', { key: 'reservePlaces', data: [] });

      if (response?.status === 200) {
        const data = response.data;
        return data;
      } else {
        throw new Error('No se pudo reservar los asientos');
      }
    } catch (error) {
      console.error(error);
      return false; // Devolver false en caso de error
    }
  },
  async fetchCupon({ commit }, orderData) {
    try {
      const url = '/cupones/get_by_name';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status == 200) {
        const status = response.data.status;
        const data = response.data;

        if (status) {
          const cupon = response.data.payload.cupon;
          commit('SET_DATA', { key: 'cupon', data: cupon || false });
        }

        return data;
      } else {
        throw new Error('No se pudo obtener la información del cupon');
      }
    } catch (error) {
      console.error('Error al obtener el cupon:', error);
    }
  },
  async fetchEfNumber({ commit }, orderData) {
    try {
      const url = '/taquilla/ef';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status == 200) {
        const status = response.data.status;
        const data = response.data;

        if (status) {
          const cupon = response.data.payload.cupon;
          commit('SET_DATA', { key: 'cupon', data: cupon || false });
        }

        return data;
      } else {
        throw new Error('No se pudo obtener la información del cupon');
      }
    } catch (error) {
      console.error('Error al obtener el cupon:', error);
    }
  },
  async sendTickets({ commit }, orderData) {
    try {
      const url = '/taquilla/send_tickets';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status == 200) {
        const status = response.data.status;
        const data = response.data;

        if (status) {
          commit('SET_DATA', { key: 'sendPost', data: true });
        }

        return data;
      } else {
        throw new Error('No se pudo re-enviar el correo');
      }
    } catch (error) {
      console.error('Error al enviar el correo:', error);
    }
  },
};

export default actions;