<script>
import { mapState, mapMutations } from 'vuex';
import LoadingComponent from '@/components/common/loading';
import HeaderTaquilla from '@/modules/taquilla/header';

export default {
  name: 'LayoutTaquilla',
  components: {
    LoadingComponent,
    HeaderTaquilla,
  },
  data() {
    return {
      sessionInterval: null,
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
    }),
  },
  mounted() {
    this.validSession();
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    setInterval(this.validSession, 60000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
    this.validSession();
  },
  watch: {},
  methods: {
    ...mapMutations({
      authSetData: 'auth/SET_DATA',
    }),
    checkMobile() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 1023;
      this.$store.commit('ui/SET_DATA', { key: 'isMobile', data: isMobile });
    },
    validSession() {
      const admin = JSON.parse(localStorage.getItem('admin'));
      const adminToken = localStorage.getItem('adminToken');
      const adminTokenTime = localStorage.getItem('adminTokenTime');

      if (!admin || !adminToken || !adminTokenTime) {
        localStorage.removeItem('admin');
        localStorage.removeItem('adminToken');
        localStorage.removeItem('adminTokenTime');

        if (this.$route.path !== '/login') {
          this.$router.push('/login');
        }
        return;
      }

      const currentTime = new Date().getTime();
      const sessionTimestamp = parseInt(adminTokenTime, 10);

      // Verificar si han pasado más de 3 horas (10800000 ms)
      if (currentTime - sessionTimestamp > 10800000) {
        // Remover datos de sesión expirados
        localStorage.removeItem('admin');
        localStorage.removeItem('adminToken');
        localStorage.removeItem('adminTokenTime');
        this.$router.push('/login');
        return;
      }

      this.authSetData({ key: 'admin', data: admin });
      this.authSetData({ key: 'adminToken', data: adminToken });
    },
  },
};
</script>

<template>
  <div class="main-taquilla">
    <HeaderTaquilla></HeaderTaquilla>
    <main class="taquilla-content">
      <router-view />
    </main>
    <LoadingComponent></LoadingComponent>
  </div>
</template>
