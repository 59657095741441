// Import
import Vue from 'vue';
import Router from 'vue-router';
import Paths from './paths';

// Use Vue Router
Vue.use(Router);

// Start Vue Router
const router = new Router({
  mode: 'history', // Descomenta esta línea para habilitar el modo de historial
  routes: [
    ...Paths,
  ],
});


// Middleware
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const adminToken = localStorage.getItem('adminToken');

  if (authRequired && !adminToken) {
    return next('/login');
  }

  if (to.path === '/login' && adminToken) {
    return next('/eventos');
  }

  next();
});

// Export
export default router;
